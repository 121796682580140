import { BetaMapping, IMappings } from '../../models';

export interface State {
  data: IMappings | BetaMapping;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = {
  data: null,
  isLoading: false,
  error: null,
};
