import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

import { generateParamArray } from '../core/helpers/params-generator';
import { TokenService } from '../core/services/token.service';
import { IAccommodationChannelFormValue } from '../features/commons/accommodations-mappings/models/accommodations-mappings.model';
import { ISingleUse } from '../models';
import { IResponseSuccess } from '../core/models/response-sucess.model';

@Injectable({
  providedIn: 'root',
})
export class MappingsService {
  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
  ) {}

  load(propertiesIds: number[], betaVersion: boolean, withMapping: boolean) {
    return this.http.get(
      `channelmanager/accommodations_mapping?${generateParamArray(
        'property_id',
        propertiesIds,
      )}${betaVersion ? '&beta_version=' + betaVersion : ''}${
        withMapping ? '&show_mapping=' + +withMapping : ''
      }`,
    );
  }

  download(propertyId: number, channelId: number) {
    return this.http.get(
      `channelmanager/accommodations/${channelId}/${propertyId}/new?with_channel_response=1`,
    );
  }

  setSingleUse(request: ISingleUse) {
    const { channel_id, property_id } = request;
    return this.http.post(
      `channelmanager/single_use/${channel_id}/${property_id}`,
      request,
    );
  }

  deleteSingleUse(request: ISingleUse) {
    const { channel_id, property_id, accommodations_channel } = request;
    return this.http.delete(
      `channelmanager/single_use/${channel_id}/${property_id}?${generateParamArray(
        'accommodations_channel',
        accommodations_channel,
      )}`,
    );
  }

  update(newAccommodationsMappingsData: IAccommodationChannelFormValue[]) {
    const channelMappings = newAccommodationsMappingsData.reduce(
      (channelsMappings: any[], mapping: IAccommodationChannelFormValue) => {
        const foundChannelMappingIndex = channelsMappings.findIndex(
          (currentMapping: IAccommodationChannelFormValue) =>
            currentMapping.channel_id === mapping.channel_id &&
            currentMapping.property_id === mapping.property_id,
        );
        if (foundChannelMappingIndex === -1) {
          channelsMappings.push({
            property_id: mapping.property_id,
            channel_id: mapping.channel_id,
            occupancy: mapping.occupancy,
            mappings: [{ ...mapping.mappings, type: mapping.type }],
          });
        } else {
          channelsMappings[foundChannelMappingIndex].mappings.push({
            ...mapping.mappings,
            type: mapping.type,
          });
        }
        return channelsMappings;
      },
      [],
    );
    return forkJoin(
      ...channelMappings.map((channelMapping) => {
        return this.http.post(
          `channelmanager/accommodations_mapping/${channelMapping.channel_id}/${channelMapping.property_id}`,
          channelMapping,
        );
      }),
    );
  }
}
