import { createAction, props } from '@ngrx/store';

import { IAccommodationChannelFormValue } from '../../features/commons/accommodations-mappings/models/accommodations-mappings.model';
import { BetaMapping, IMappings, ISingleUse } from '../../models';
import { IResponseSuccess } from '../../core/models/response-sucess.model';

export const loadRequest = createAction(
  '[Mapping] Load Request',
  props<{
    propertiesIds: number[];
    betaVersion?: boolean;
    withMapping?: boolean;
    onSuccess?: (data: IResponseSuccess<IMappings>) => void;
    onFailure?: () => void;
  }>(),
);

export const loadSuccess = createAction(
  '[Mapping] Load Success',
  props<{ mappings: IMappings | BetaMapping }>(),
);

export const loadFailure = createAction(
  '[Mapping] Load Failure',
  props<{ error: any }>(),
);

export const downloadRequest = createAction(
  '[Mapping] Download Request',
  props<{ propertyId: number; channelId: number }>(),
);

export const downloadSuccess = createAction('[Mapping] Download Success');

export const downloadFailure = createAction(
  '[Mapping] Download Failure',
  props<{ error: any }>(),
);

export const setSingleUseRequest = createAction(
  '[Mapping] Set Single Use Request',
  props<{ request: ISingleUse }>(),
);

export const setSingleUseSuccess = createAction(
  '[Mapping] Set Single Use Success',
);

export const setSingleUseFailure = createAction(
  '[Mapping] Set Single Use Failure',
  props<{ error: any }>(),
);

export const deleteSingleUseRequest = createAction(
  '[Mapping] Delete Single Use Request',
  props<{ request: ISingleUse }>(),
);

export const deleteSingleUseSuccess = createAction(
  '[Mapping] Delete Single Use Success',
);

export const deleteSingleUseFailure = createAction(
  '[Mapping] Delete Single Use Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Mapping] Update Request',
  props<{
    newAccommodationsMappingsData: IAccommodationChannelFormValue[];
    propertiesIds: number[];
    reloadData?: boolean;
    reloadWithBetaVersion?: boolean;
    reloadWithMapping?: boolean;
  }>(),
);

export const updateSuccess = createAction('[Mapping] Update Success');

export const updateFailure = createAction(
  '[Mapping] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Mapping] Reset State');
