import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { effectHooks } from '../../helpers';
import { RequestCacheService } from '../../services';
import { MappingsService } from '../../services/mappings.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class MappingsStoreEffects {
  constructor(
    private dataService: MappingsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private requestCache: RequestCacheService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(
        ({ propertiesIds, betaVersion, withMapping, onSuccess, onFailure }) => {
          this.requestCache.delete('channelmanager/accommodations_mapping');
          return this.dataService
            .load(propertiesIds, betaVersion, withMapping)
            .pipe(
              effectHooks({ onSuccess, onFailure }),

              map((response) =>
                fromActions.loadSuccess({
                  mappings: response.data,
                }),
              ),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.loadFailure(error));
              }),
            );
        },
      ),
    ),
  );

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadRequest),
      switchMap(({ propertyId, channelId }) =>
        this.dataService.download(propertyId, channelId).pipe(
          map((response: IResponseSuccess) => {
            this.notifications.success('notifications.generic_success');
            return fromActions.downloadSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.downloadFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(
        ({
          newAccommodationsMappingsData,
          propertiesIds,
          reloadData,
          reloadWithBetaVersion,
          reloadWithMapping,
        }) =>
          this.dataService.update(newAccommodationsMappingsData).pipe(
            mergeMap(() => {
              this.notifications.updateSuccess('accommodations_mappings');
              if (reloadData) {
                return [
                  fromActions.loadRequest({
                    propertiesIds,
                    betaVersion: reloadWithBetaVersion,
                    withMapping: reloadWithMapping,
                  }),
                  fromActions.updateSuccess(),
                ];
              }
              return [fromActions.updateSuccess()];
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.updateFailure(error));
            }),
          ),
      ),
    ),
  );

  setSingleUse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setSingleUseRequest),
      switchMap(({ request }) =>
        this.dataService.setSingleUse(request).pipe(
          map(() => {
            this.notifications.updateSuccess('accommodations_mappings');

            return fromActions.setSingleUseSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setSingleUseFailure(error));
          }),
        ),
      ),
    ),
  );

  deleteSingleUse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteSingleUseRequest),
      switchMap(({ request }) =>
        this.dataService.deleteSingleUse(request).pipe(
          map(() => {
            this.notifications.updateSuccess('accommodations_mappings');

            return fromActions.deleteSingleUseSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteSingleUseFailure(error));
          }),
        ),
      ),
    ),
  );
}
