import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  AccommodationsChannelsMapping,
  BetaMapping,
  IMappings,
} from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getMappingsData = (state: State) => state.data;

export const selectState = createFeatureSelector<State>('mappings');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectMappingsData = createSelector(selectState, getMappingsData);

export const selectAccommodationsChannels = createSelector(
  selectState,
  (state) => {
    const mapping = state.data as BetaMapping;

    let accommodationsChannels: AccommodationsChannelsMapping;

    Object.values(mapping || {}).forEach(({ accommodations }) =>
      Object.values(accommodations || {}).forEach(
        ({ rateplans, accommodation_id }) => {
          Object.values(rateplans || {}).forEach(({ channels }) => {
            Object.keys(channels || {}).forEach((channelId) => {
              accommodationsChannels = {
                ...accommodationsChannels,
                [accommodation_id]: {
                  ...accommodationsChannels?.[accommodation_id],
                  [channelId]: true,
                },
              };
            });
          });
        },
      ),
    );

    return accommodationsChannels;
  },
);

export const selectPropertyAccommodationsChannels = createSelector(
  selectState,
  (state) => {
    const mappings = state.data as IMappings;

    let accommodations: {
      [propertyId: number]: Array<{
        name: string;
        id: number;
      }>;
    } = {};

    Object.keys(mappings || {}).forEach((propertyId) => {
      accommodations = {
        ...accommodations,
        [propertyId]: mappings[propertyId].accommodations_local.map(
          ({ accommodation_id, accommodation_name }) => ({
            id: accommodation_id,
            name: accommodation_name,
          }),
        ),
      };
    });
    return accommodations;
  },
);

export const selectRateplansAccommodationsChannels = createSelector(
  selectState,
  (state) => {
    const mappings = state.data as IMappings;

    let rateplans: {
      [accommodationId: number]: Array<{
        name: string;
        id: number;
      }>;
    } = {};

    Object.keys(mappings || {}).forEach((propertyId) => {
      mappings[propertyId].accommodations_local.forEach((accommodation) => {
        const { accommodation_id, rateplans: rateplasAccommodation } =
          accommodation;

        rateplans = {
          ...rateplans,
          [accommodation_id]: (rateplasAccommodation || [])
            .filter((rateplan) => rateplan.type === 'plan')
            .map(({ rateplan_id, rateplan_name }) => ({
              id: rateplan_id,
              name: rateplan_name,
            })),
        };
      });
    });
    return rateplans;
  },
);
